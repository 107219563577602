import React from "react";
import { dialogTypes } from "../utils/enums";
import { Loading, Dialog } from "./";

const Wrapper = ({
  children,
  isDownloading,
  isDialogVisible,
  dialogMessage,
  handleCloseDialog,
  handleAccept,
  handleDelete,
}) => {
  return (
    <>
      <Loading visible={!!isDownloading} />
      <Dialog
        open={isDialogVisible(dialogTypes.accept)}
        handleClose={handleCloseDialog}
        title="Akceptacja"
        content={dialogMessage}
        acceptOnClick={handleAccept}
        cancelOnClick={handleCloseDialog}
        isLoading={isDownloading}
      />
      <Dialog
        open={isDialogVisible(dialogTypes.remove)}
        handleClose={handleCloseDialog}
        title="Usuwanie"
        content={dialogMessage}
        acceptTitle="Usuń"
        acceptOnClick={handleDelete}
        cancelOnClick={handleCloseDialog}
        isLoading={isDownloading}
      />
      <Dialog
        open={isDialogVisible(dialogTypes.info)}
        handleClose={handleCloseDialog}
        title="Informacja"
        content={dialogMessage}
        acceptOnClick={handleCloseDialog}
      />
      <Dialog
        open={isDialogVisible(dialogTypes.error)}
        handleClose={handleCloseDialog}
        title="Wystąpił problem"
        content={dialogMessage}
        acceptOnClick={handleCloseDialog}
      />
      {children}
    </>
  );
};

export default Wrapper;
