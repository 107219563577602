import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Dialog, Grid, Wrapper } from "../components";
import { receiptCategories, dialogTypes, fetchTypes } from "../utils/enums";
import { NewReceipt } from "./";
import { useLoadingState, useDialogState, useFetchData } from "../utils/hooks";
import { Endpoints } from "../utils/endpoints";
import { createdReceiptColumns } from "../utils/columns";
import { Button } from "@mui/material";
import { Send, ShoppingBasket } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setFilters } from "../stores/filtersStore";

const CreatedReceipts = () => {
  const dispatch = useDispatch();
  const { selectedIds } = useSelector((state) => state.filters.data);
  const [activeItem, setActiveItem] = useState(null);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [isCombinedShipping, setIsCombinedShipping] = useState(false);

  const {
    isDownloading,
    isWaitingForResponse,
    changeWaitingForResponse,
    changeDownloading,
  } = useLoadingState();
  const { dialogMessage, isDialogVisible, setDialog } = useDialogState();
  const { fetchData } = useFetchData({
    changeDownloading,
    changeWaitingForResponse,
  });

  useEffect(() => {
    return () => {
      setActiveItem(null);
    };
  }, []);

  const handleCloseDialog = () => {
    setDialog();
    setActiveItem(null);
    if (isCombinedShipping) hangleSwitchCombinedShipping();
  };

  const handleSetShouldRefresh = (value) => {
    setShouldRefresh(!!value);
  };

  const handleAcceptItem = (item) => {
    setActiveItem(item);
    setDialog(
      dialogTypes.accept,
      `Czy na pewno chcesz wysłać receptę ${item.barcodeText} dla klienta: ${item.clientName}.`
    );
  };

  const hangleSwitchCombinedShipping = () => {
    dispatch(setFilters({ selected: [], selectedIds: [] }));
    setIsCombinedShipping((prevState) => !prevState);
  };

  const handleAcceptReceipt = async () => {
    if (isCombinedShipping) {
      const response = await fetchData(fetchTypes.post, Endpoints.sendBatch, {
        id: selectedIds,
      });
      if (response.success) {
        hangleSwitchCombinedShipping();
        handleSetShouldRefresh(true);
        setDialog(dialogTypes.info, `Recepty zostały wysłane.`);
      } else {
        hangleSwitchCombinedShipping();
        setDialog(dialogTypes.error, response.message);
      }
    } else {
      const response = await fetchData(
        fetchTypes.patch,
        `${Endpoints.receiptsHistory}${activeItem.id}/`,
        { status: 1 }
      );
      if (response.success) {
        handleSetShouldRefresh(true);
        setDialog(
          dialogTypes.info,
          `Recepta ${activeItem.barcodeText} została wysłana.`
        );
      } else {
        setActiveItem(null);
        setDialog(dialogTypes.error, response.message);
      }
    }
  };

  const handleEditItem = async (item) => {
    setDialog();

    const response = await fetchData(
      fetchTypes.get,
      `${Endpoints.receiptsHistory}${item.id}/`
    );
    if (response.success) {
      setActiveItem(response.data);
      setDialog(dialogTypes.edit, "");
    } else {
      setDialog(dialogTypes.error, response.message);
    }
  };

  const handleCopyItem = async (item) => {
    setDialog();

    const response = await fetchData(
      fetchTypes.get,
      `${Endpoints.receiptsHistory}${item.id}/`
    );
    if (response.success) {
      setActiveItem(response.data);
      setDialog(dialogTypes.add, "");
    } else {
      setDialog(dialogTypes.error, response.message);
    }
  };

  const handleUpdateReceiptsList = async () => {
    setDialog();

    const response = await fetchData(
      fetchTypes.get,
      `${Endpoints.receiptsHistoryMinified}?status=0`
    );
    if (response.success) {
      handleSetShouldRefresh(true);
      handleCloseDialog();
    } else {
      setActiveItem(null);
      setDialog(dialogTypes.error, response.message);
    }
  };

  const handleDeleteItem = (item) => {
    setActiveItem(item);
    setDialog(
      dialogTypes.remove,
      `Czy na pewno chcesz usunąć receptę ${item.barcodeText} dla klienta: ${item.clientName}?`
    );
  };

  const handleDeleteReceipt = async () => {
    const response = await fetchData(
      fetchTypes.delete,
      `${Endpoints.receiptsHistory}${activeItem.id}/`
    );
    if (response.success) {
      handleSetShouldRefresh(true);
      setDialog(
        dialogTypes.info,
        `Recepta ${activeItem.barcodeText} została usunięta.`
      );
    } else {
      setDialog(dialogTypes.error, response.message);
    }
  };

  const handleToggleFavoriteReceipt = async (item) => {
    const response = await fetchData(fetchTypes.post, Endpoints.favorites, {
      id: [item.id],
      delete: item.isFavourite,
    });

    if (response.success) {
      handleSetShouldRefresh(true);
      setDialog(
        dialogTypes.info,
        item.isFavourite
          ? `Recepta ${item.barcodeText} została usunięta z ulubionych.`
          : `Recepta ${item.barcodeText} została dodana do ulubionych.`
      );
    } else {
      setDialog(dialogTypes.error, response.message);
    }
  };

  const handleSendCombinedShipping = () => {
    setDialog(
      dialogTypes.accept,
      `Czy na pewno chcesz wysłać zaznaczone (ilość: ${selectedIds.length}) recepty w jednej przesyłce?`
    );
  };

  const ToolbarActions = () =>
    isCombinedShipping ? (
      <>
        <Button
          variant="contained"
          onClick={handleSendCombinedShipping}
          startIcon={<Send />}
          disabled={!selectedIds.length}
        >
          Wyślij zaznaczone
        </Button>
        <Button onClick={hangleSwitchCombinedShipping}>Anuluj wysyłkę</Button>
      </>
    ) : (
      <Button
        variant="contained"
        onClick={hangleSwitchCombinedShipping}
        startIcon={<ShoppingBasket />}
      >
        Wysyłka łączona
      </Button>
    );

  return (
    <Wrapper
      isDownloading={isDownloading}
      isDialogVisible={isDialogVisible}
      dialogMessage={dialogMessage}
      handleCloseDialog={handleCloseDialog}
      handleAccept={handleAcceptReceipt}
      handleDelete={handleDeleteReceipt}
    >
      <Dialog
        open={isDialogVisible(dialogTypes.edit)}
        handleClose={handleCloseDialog}
        title="Edytowanie recepty"
        content={
          <NewReceipt
            activeItem={activeItem}
            handleSuccessActiveItem={handleUpdateReceiptsList}
            isEditMode
          />
        }
        maxWidth="lg"
      />
      <Dialog
        open={isDialogVisible(dialogTypes.add)}
        handleClose={handleCloseDialog}
        title="Tworzenie recepty"
        content={
          <NewReceipt
            activeItem={activeItem}
            handleSuccessActiveItem={handleUpdateReceiptsList}
          />
        }
        maxWidth="lg"
      />

      <Grid
        type={receiptCategories.created}
        columns={createdReceiptColumns}
        url={`${Endpoints.receiptsHistory}?status=0`}
        title="Stworzone recepty"
        checking={isCombinedShipping}
        expanding={true}
        expandContent="receipt"
        onAcceptItem={handleAcceptItem}
        onEditItem={handleEditItem}
        onCopyItem={handleCopyItem}
        onDeleteItem={handleDeleteItem}
        onFavoriteItem={handleToggleFavoriteReceipt}
        initialOrderBy="dateCreated"
        shouldRefresh={shouldRefresh}
        setRefresh={handleSetShouldRefresh}
        toolbarActions={<ToolbarActions />}
        disabled={isCombinedShipping || isWaitingForResponse}
      />
    </Wrapper>
  );
};

export default CreatedReceipts;
