import React, { forwardRef } from "react";
import {
  Box,
  Dialog as MuiDialog,
  DialogContent,
  DialogTitle,
  Slide,
  DialogActions,
  Button,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Dialog = ({
  open,
  handleClose,
  title,
  content,
  fullWidth = true,
  maxWidth = "xs",
  acceptTitle = "Ok",
  acceptOnClick,
  cancelTitle = "Anuluj",
  cancelOnClick,
  isLoading,
  ...rest
}) => {
  return (
    <MuiDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      {...rest}
    >
      <DialogTitle sx={{ padding: "10px 24px" }}>
        {title}
        {!!handleClose ? (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            size="small"
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ padding: "10px 0" }}>{content}</Box>
      </DialogContent>
      {cancelOnClick || acceptOnClick ? (
        <DialogActions>
          {cancelOnClick && (
            <Button onClick={cancelOnClick} disabled={isLoading}>
              {cancelTitle}
            </Button>
          )}
          {acceptOnClick && (
            <LoadingButton
              onClick={acceptOnClick}
              variant="contained"
              loading={isLoading}
            >
              {acceptTitle}
            </LoadingButton>
          )}
        </DialogActions>
      ) : null}
    </MuiDialog>
  );
};

export default Dialog;
