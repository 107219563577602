import { useEffect, useState } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { validate } from "../validators";
import { getClientAddress } from "../helpers/app";
import {
  initialFormData,
  initialErrorData,
  initialPriceData,
  initialErrorPriceData,
  calculateOrder,
  getOrderData,
} from "../../pages/newOrder/utlis";
import { dialogTypes, fetchTypes } from "../enums";
import { Endpoints } from "../endpoints";

export const useOrderManagement = ({
  setIsCalculated,
  fetchData,
  setDialog,
}) => {
  const settings = useSelector((state) => state.auth.settings);
  const [formData, setFormData] = useState(initialFormData);
  const [errorData, setErrorData] = useState(initialErrorData);
  const [priceData, setPriceData] = useState(initialPriceData);
  const [errorPriceData, setErrorPriceData] = useState(initialErrorPriceData);

  useEffect(() => {
    async function getReceipt() {
      const response = await fetchData(
        fetchTypes.get,
        `${Endpoints.receiptsHistory}${formData.receipt.id}/`
      );
      if (response.success) {
        setFormData((prevState) => ({
          ...prevState,
          client: response.data.client,
          address: getClientAddress(response.data.deliveryAddress),
        }));
      } else {
        setDialog(dialogTypes.error, response.message);
      }
    }

    if (!!formData.receipt?.id) getReceipt();
  }, [formData.receipt?.id]);

  // useEffect(() => {
  //   setPriceData((prevState) => ({
  //     ...prevState,
  //     deliveryCost: settings.deliveryCost,
  //   }));
  // }, [settings]);

  const clearStates = () => {
    setFormData({
      ...initialFormData,
      products: _.map(initialFormData.products, (product) => ({
        ...product,
      })),
    });
    setErrorData({ ...initialErrorData });
    setPriceData({ ...initialPriceData });
    setErrorPriceData({ ...initialErrorPriceData });
  };

  useEffect(() => {
    return () => {
      clearStates();
    };
  }, []);

  const handleChangeForm = (e, obj) => {
    const isAutocomplete = e.target.id.split("-")[0] === "autocomplete";
    const newFormData = _.cloneDeep(formData);

    if (isAutocomplete) {
      const objName = e.target.id.split("-")[1];
      if (objName === "products") {
        const index = e.target.id.split("-")[2];
        const subObjName = e.target.id.split("-")[3];

        if (subObjName === "count") {
          _.set(newFormData, ["products", index, subObjName], e.target.value);
        } else {
          _.set(newFormData, ["products", index, "value"], obj);
        }
      } else {
        _.set(newFormData, objName, obj);
        if (objName === "client") {
          _.set(newFormData, "address", getClientAddress(obj.address));
        }
      }
    } else {
      _.set(newFormData, e.target.id, e.target.value);
    }

    setFormData({ ...newFormData });
  };

  const handleAddProduct = () => {
    const newValue = {
      id: formData.products.length,
      value: null,
      count: "",
      price: "",
      priceVat: "",
    };

    setFormData((prevState) => ({
      ...prevState,
      ["products"]: [...prevState.products, newValue],
    }));
  };

  const handleDeleteProduct = (id) => {
    const newFormData = {
      ...formData,
      ["products"]: _.map(
        _.filter(formData["products"], (product) => product.id !== id),
        (item, index) => ({ ...item, id: index })
      ),
    };

    setFormData({ ...newFormData });
  };

  const handleCheckProducts = () => {
    const newProducts = _.filter(
      _.cloneDeep(formData.products),
      (product) => _.isObject(product.value) || !!product.count
    );
    const errorsData = [];
    const productsToCalculate = [];

    if (!newProducts.length)
      newProducts.push({
        id: 0,
        value: null,
        count: "",
        price: "",
        priceVat: "",
      });

    _.forEach(newProducts, (product) => {
      if (
        !_.isObject(product.value) &&
        !!product.count &&
        product.count !== "0"
      )
        errorsData.push({
          id: product.id,
          value: true,
          count: false,
        });
      else if (
        _.isObject(product.value) &&
        (!product.count || product.count === "0")
      )
        errorsData.push({
          id: product.id,
          value: false,
          count: true,
        });
      else if (
        !_.isObject(product.value) &&
        (!product.count || product.count === "0")
      )
        errorsData.push({
          id: product.id,
          value: true,
          count: true,
        });
      else if (
        _.isObject(product.value) &&
        product.count > product.value.inMagazine
      )
        errorsData.push({
          id: product.id,
          value: false,
          count: false,
          maxCount: true,
        });
      else productsToCalculate.push(product);
    });

    _.forEach(productsToCalculate, (item) => {
      _.set(
        _.find(newProducts, { id: item.id }),
        "price",
        (+item.count * item.value.price).toFixed(2)
      );
      _.set(
        _.find(newProducts, { id: item.id }),
        "priceVat",
        (+item.count * item.value.priceVat).toFixed(2)
      );
    });

    return {
      products: newProducts,
      productsError: errorsData,
    };
  };

  const handleCalculateOrder = () => {
    const { products, productsError } = handleCheckProducts();

    const duplicateProducts = _.filter(
      _.map(products, (item) => item?.value?.name),
      (item, index, iteratee) => _.includes(iteratee, item, index + 1)
    );

    if (!!duplicateProducts.length) {
      const errors = {
        ...errorData,
        form: {
          value: true,
          message: `Następujące produkty powtarzają się: ${duplicateProducts.join(
            ", "
          )}`,
        },
      };
      const productsList = [];
      _.forEach(duplicateProducts, (duplicateItem) => {
        const lastItem = _.findLast(
          products,
          (product) => product.value?.name === duplicateItem
        );
        productsList.push({
          id: lastItem.id,
          value: true,
          count: false,
        });
      });
      _.set(errors, "products", productsList);
      setErrorData({ ...errors });
      return;
    }

    setFormData((prevState) => ({
      ...prevState,
      ["products"]: products,
    }));

    const { success, errors } = validate(formData, initialErrorData);

    if (success && !productsError.length) {
      setErrorData({
        ...initialErrorData,
        form: { value: false, message: "" },
      });
      setPriceData(calculateOrder(priceData, products, formData, settings));
      setIsCalculated(true);
    } else {
      const errorsSum = { ...errors };
      if (!!productsError.length) {
        _.set(errorsSum, "products", productsError);
      }
      setErrorData({ ...errorsSum });
    }
  };

  const handleChangePriceForm = (e, obj) => {
    const isAutocomplete = e?.target?.id?.split("-")[0] === "autocomplete";
    const newPriceData = _.cloneDeep(priceData);

    if (isAutocomplete) {
      const objName = e?.target?.id.split("-")[1];
      _.set(newPriceData, objName, obj);
    } else if (!!e?.target) {
      _.set(newPriceData, e.target.id, e.target.value);
    }

    setPriceData({
      ...calculateOrder(newPriceData, formData.products, formData, settings),
    });
  };

  const handleSubmitReceipt = async (type, url, onSuccess) => {
    setErrorPriceData({ ...initialErrorPriceData });
    const body = getOrderData(formData, priceData);

    const { success, errors } = validate(priceData, initialErrorPriceData);
    if (success) {
      const response = await fetchData(type, url, body);
      if (response?.success) {
        onSuccess(response);
      } else {
        setErrorPriceData((prevState) => ({
          ...prevState,
          form: { value: true, message: response.message },
        }));
      }
    } else {
      setErrorPriceData({ ...errors });
    }
  };

  return {
    formData,
    errorData,
    priceData,
    errorPriceData,
    clearStates,
    handleChangeForm,
    handleAddProduct,
    handleDeleteProduct,
    handleCalculateOrder,
    handleChangePriceForm,
    handleSubmitReceipt,
  };
};
